import { DAYS } from '@/common/constants/days'
import { IFormValues } from './NewLocation.types'

export const DEFAULT_VALUES: IFormValues = Object.freeze({
  name: '',

  address: {
    city: '',
    street: '',
  },

  coordinates: {
    latitude: 0,
    longitude: 0,
  },

  workingDays: DAYS.map((day) => ({
    day,
    workingHours: [],
  })),
})

export const DEFAULT_USER_COUNTRY = 'Switzerland'
