import { createSlice } from '@reduxjs/toolkit'
import { countriesApi } from './Countries.service'
import { TState } from './Countries.types'

const initialState: TState = {
  isLoaded: false,
  list: [],
}

const countriesSlice = createSlice({
  name: 'Countries',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addMatcher(countriesApi.endpoints.getCountries.matchFulfilled, (state, action) => {
      state.list = action.payload
      state.isLoaded = true
    })
  },
})

export default countriesSlice.reducer
