import { ICustomResponseError, IResponseSerializedError } from '@/app/services/api.types'

export const serializeApiError = (
  errorResponse?: ICustomResponseError,
): IResponseSerializedError => ({
  message: errorResponse?.status || 'Undefined error code',
  description:
    typeof errorResponse?.data?.error === 'object'
      ? errorResponse?.data?.error.message
      : errorResponse?.data?.error,
})
