import { TRootState } from '@/app/store'
import { createSelector } from '@reduxjs/toolkit'

import { DEFAULT_USER_COUNTRY } from '../Locations/components/NewLocation/NewLocation.constants'

export const userSelector = (store: TRootState) => store.user
export const isUserLoadedSelector = (store: TRootState) => store.user.isLoaded
export const userDataSelector = (store: TRootState) => store.user.data
export const userCountrySelector = (store: TRootState) => store.user.data?.country
export const userLocationsSelector = (store: TRootState) => store.user.data?.locations
export const userCategorySelector = (store: TRootState) => store.user.data?.category
export const userBusyDatesSelector = (store: TRootState) => store.user.data?.busyDates
export const userLocationsCountSelector = createSelector(
  userLocationsSelector,
  (locations) => locations?.length ?? 0,
)
export const isDefaultUserCountrySelector = createSelector(
  userCountrySelector,
  (country) => country === DEFAULT_USER_COUNTRY,
)
