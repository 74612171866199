import { FC, useMemo } from 'react'

// UIKit
import { Grid, Modal as AntModal, ModalProps } from 'antd'

const { useBreakpoint } = Grid

type TModalTypes = 'default' | 'wide'

interface IProps extends ModalProps {
  destroyOnClose?: boolean
  type?: TModalTypes
}

export const Modal: FC<IProps> = ({ type, destroyOnClose = true, children, ...otherProps }) => {
  const screen = useBreakpoint()

  const width = useMemo(() => {
    if (type === 'default') return '50vw'

    if ((screen.xs || screen.sm || screen.md) && !screen.lg) return '90vw'

    return '50vw'
  }, [screen, type])

  return (
    <AntModal width={width} destroyOnClose={destroyOnClose} {...otherProps}>
      {children}
    </AntModal>
  )
}
