import { yupResolver } from '@hookform/resolvers/yup'
import { FC } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import * as yup from 'yup'

// Components
import { Modal } from '@/common/components/Base/Modal/Modal'
import { FormItem } from '@/common/components/Base/Form/FormItem'
import { FormControl } from '@/common/components/Base/FormControl/FormControl'
import { ILoginRequest } from '@/features/Auth/Auth.types'

interface IMutateFormValues {
  password: string
}

const VALIDATION_SCHEMA: yup.SchemaOf<IMutateFormValues> = yup
  .object({
    password: yup.string().label('Password').min(6).required(),
  })
  .required()

interface IProps {
  isLoading: boolean
  onSubmit: (value: Pick<ILoginRequest, 'password'>) => void
  onCancel: () => void
}

export const ModalRequirePassword: FC<IProps> = ({ isLoading, onSubmit, onCancel }) => {
  const formMethods = useForm<IMutateFormValues>({
    defaultValues: {
      password: '',
    },
    resolver: yupResolver(VALIDATION_SCHEMA),
  })

  return (
    <Modal
      open={true}
      title='Confirm the password'
      centered={true}
      maskClosable={false}
      closable={false}
      okButtonProps={{ loading: isLoading }}
      onOk={formMethods.handleSubmit(onSubmit)}
      onCancel={onCancel}
    >
      <FormProvider {...formMethods}>
        <FormItem label='Password' required>
          <FormControl name='password' type='password' />
        </FormItem>
      </FormProvider>
    </Modal>
  )
}
