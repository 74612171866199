import { lazy, Suspense } from 'react'
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom'
import { Spin } from 'antd'

// Components
import { MainLayout } from '@/common/components/Layout'
import { NotFound } from '@/common/components/Layout/NotFound/NotFound'
// import { ErrorLayout } from '@/common/components/Layout/ErrorLayout/ErrorLayout'

import { RequireAuth } from '@/common/components/Layout/RequireAuth/RequireAuth'
import { RequirePassword } from '@/common/components/Layout/RequirePassword/RequirePassword'
import { Forbidden } from '@/common/components/Layout/Forbidden/Forbidden'

import { ROUTES } from './routingBook'
import { RouterErrorBoundary } from '@/common/components/Layout/RouterErrorBoundary/RouterErrorBoundary'

// Features
const Auth = lazy(() => import('./Auth/Auth'))
const Locations = lazy(() => import('./Locations/Locations'))
const Location = lazy(() => import('./Locations/Location'))
const LocationCreate = lazy(() => import('./Locations/LocationCreate'))
const LocationMenus = lazy(() => import('./Locations/components/Menus/Menus'))
const LocationCategories = lazy(() => import('./Locations/components/Categories/Categories'))
const LocationDishes = lazy(() => import('./Locations/components/Dishes/Dishes'))
const LocationTables = lazy(() => import('./Locations/components/Tables/Tables'))

const Promotions = lazy(() => import('./Promotions/Promotions'))

const Reasons = lazy(() => import('./Reasons/Reasons'))

const Payments = lazy(() => import('./Payments/Payments'))

const PasswordRecovery = lazy(() => import('./PasswordRecovery/PasswordRecovery'))
const PasswordReset = lazy(() => import('./PasswordReset/PasswordReset'))

export const Routing = () => {
  return (
    <BrowserRouter>
      <Suspense fallback={<Spin size='large' />}>
        <Routes>
          <Route path={ROUTES.forbidden} element={<Forbidden />} />
          <Route path={ROUTES.notfound} element={<NotFound />} />

          <Route path={ROUTES.login} element={<Auth />} />
          <Route path={ROUTES.passwordRecovery} element={<PasswordRecovery />} />
          <Route path={ROUTES.passwordReset} element={<PasswordReset />} />

          <Route element={<RequireAuth />}>
            <Route element={<MainLayout />} errorElement={<RouterErrorBoundary />}>
              <Route path={ROUTES.locations}>
                <Route index element={<Locations />} />
                <Route path={ROUTES.newLocation} element={<LocationCreate />} />

                <Route path={ROUTES.location}>
                  <Route index element={<Location />} />
                  <Route path={ROUTES.locationMenus} element={<LocationMenus />} />
                  <Route path={ROUTES.locationCategories} element={<LocationCategories />} />
                  <Route path={ROUTES.locationDishes} element={<LocationDishes />} />
                  <Route path={ROUTES.locationTables} element={<LocationTables />} />
                </Route>
              </Route>

              <Route path={ROUTES.promotions}>
                <Route index element={<Promotions />} />
              </Route>

              <Route path={ROUTES.reasons}>
                <Route index element={<Reasons />} />
              </Route>

              <Route element={<RequirePassword />}>
                <Route path={ROUTES.payments}>
                  <Route index element={<Payments />} />
                </Route>
              </Route>
            </Route>
          </Route>

          <Route path={ROUTES.root} element={<Navigate to={ROUTES.locations} replace={true} />} />
          <Route path='*' element={<NotFound />} />
        </Routes>
      </Suspense>
    </BrowserRouter>
  )
}
