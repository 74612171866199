import { createSlice } from '@reduxjs/toolkit'

import { IAuthState, ICredentials } from './Auth.types'
import { authApi } from './Auth.service'

const initialState: IAuthState = {
  isAuthenticated: false,
  credentials: {} as ICredentials,
}

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    logout: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addMatcher(authApi.endpoints.login.matchFulfilled, (state, action) => {
      state.credentials = action.payload
      state.isAuthenticated = true
    })
    builder.addMatcher(authApi.endpoints.logout.matchFulfilled, (state) => {
      state.isAuthenticated = initialState.isAuthenticated
      state.credentials = initialState.credentials
    })
  },
})

export const { logout } = authSlice.actions

export default authSlice.reducer
