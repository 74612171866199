import { FC, useCallback, useState } from 'react'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'

import { ModalRequirePassword } from '@/common/components/Common/Modals/ModalRequirePassword'

import { ROUTES } from '@/features/routingBook'

import { useLoginMutation } from '@/features/Auth/Auth.service'

import { ILoginRequest } from '@/features/Auth/Auth.types'
import { EAppTypes } from '@/common/enums/app'

// Store
import { useAppSelector } from '@/app/store'
import { userDataSelector } from '@/features/User/User.selectors'

export const RequirePassword: FC = () => {
  const location = useLocation()
  const navigate = useNavigate()

  const [isPasswordConfirmed, setIsPasswordConfirmed] = useState(false)

  const { email } = useAppSelector(userDataSelector)

  const [login, { isLoading }] = useLoginMutation()

  const onCancel = useCallback(() => {
    const path = location.state?.prevPath.pathname ?? ROUTES.locations
    navigate(path)
  }, [navigate, location.state])

  const onSubmit = useCallback(
    (values: Pick<ILoginRequest, 'password'>) => {
      const requestValues = {
        ...values,
        email,
        appType: EAppTypes.PARTNER,
      }

      login(requestValues)
        .unwrap()
        .then(() => {
          setIsPasswordConfirmed(true)
        })
    },
    [login, email],
  )

  return isPasswordConfirmed ? (
    <Outlet />
  ) : (
    <ModalRequirePassword isLoading={isLoading} onCancel={onCancel} onSubmit={onSubmit} />
  )
}
