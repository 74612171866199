import { IUser } from '@/common/models/user'
import { createSlice } from '@reduxjs/toolkit'

import { userApi } from './User.service'

import { IState } from './User.types'

const initialState: IState = {
  isLoaded: false,
  data: {} as IUser,
}

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addMatcher(userApi.endpoints.getUser.matchRejected, (state) => {
      state.isLoaded = false
    })

    builder.addMatcher(userApi.endpoints.getUser.matchFulfilled, (state, action) => {
      state.isLoaded = true
      state.data = action.payload
    })
  },
})

export default userSlice.reducer
