import { FC } from 'react'
import { useController, useFormContext } from 'react-hook-form'

// Styles
import styles from './FormControl.module.scss'

// UIKit
import { Input, InputProps } from 'antd'

// Components
import { HelperText } from '../HelperText/HelperText'

export interface IFormControlProps extends InputProps {
  name: string
  disabled?: boolean
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  renderInput?: any
}

export const FormControl: FC<IFormControlProps> = ({ name, renderInput, ...props }) => {
  const { control } = useFormContext()
  const {
    field,
    fieldState: { error },
  } = useController({
    name,
    control,
  })

  const Element = renderInput || Input

  return (
    <div className={styles.root}>
      <Element {...field} {...props} status={error ? 'error' : undefined} />
      {error && <HelperText text={error?.message} />}
    </div>
  )
}
