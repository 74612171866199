import { FC } from 'react'
import { Button, Result } from 'antd'

import { ROUTES } from '@/features/routingBook'
import { useNavigate } from 'react-router-dom'
import { Layout } from '../Layout/Layout'

export const NotFound: FC = () => {
  const navigate = useNavigate()
  const onClick = () => navigate(ROUTES.root)

  return (
    <Layout>
      <Result
        status='404'
        title='404'
        subTitle='Page not found '
        extra={
          <Button type='primary' onClick={onClick}>
            Back Home
          </Button>
        }
      />
    </Layout>
  )
}
