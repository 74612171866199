import { api } from '@/app/services/api'

import { ICredentials, ILoginRequest } from '@/features/Auth/Auth.types'

const ROOT_ENDPOINT = 'auth'

export const authApi = api.injectEndpoints({
  endpoints: (builder) => ({
    login: builder.mutation<ICredentials, ILoginRequest>({
      query: (credentials) => ({
        url: `${ROOT_ENDPOINT}/login`,
        method: 'POST',
        body: credentials,
      }),
      extraOptions: { maxRetries: 0 },
    }),

    logout: builder.mutation<void, void>({
      query: () => ({
        url: `${ROOT_ENDPOINT}/logout`,
        method: 'POST',
      }),
      extraOptions: { maxRetries: 0 },
    }),
  }),
})

export const { useLoginMutation, useLogoutMutation } = authApi

export const {
  endpoints: { login, logout },
} = authApi
