import { FC, ReactNode } from 'react'

// Styles
import styles from './Form.module.scss'

// UIKit
import { Form } from 'antd'
import type { FormItemProps } from 'antd/es/form/FormItem'

const { Item } = Form

export interface IFormItemProps extends FormItemProps {
  children: ReactNode
}

export const FormItem: FC<IFormItemProps> = ({ children, ...props }) => (
  <Item className={styles.formItem} {...props}>
    {children}
  </Item>
)
