import { FC, useCallback, useMemo } from 'react'
import { Link, useLocation } from 'react-router-dom'

// Styles
import styles from './Sidebar.module.scss'

// UIKit
import { Layout, Menu } from 'antd'
import {
  StarOutlined,
  LogoutOutlined,
  CameraOutlined,
  ReadOutlined,
  StrikethroughOutlined,
} from '@ant-design/icons'

// Services
import { useLogoutMutation } from '@/features/Auth/Auth.service'

// Constants
import { ROUTES } from '@/features/routingBook'

const menuList = [
  {
    key: ROUTES.locations,
    icon: <CameraOutlined />,
    label: 'Locations',
    to: ROUTES.locations,
  },
  {
    key: ROUTES.promotions,
    icon: <StarOutlined />,
    label: 'Promotions',
    to: ROUTES.promotions,
  },
  {
    key: ROUTES.reasons,
    icon: <ReadOutlined />,
    label: 'Reject reasons',
    to: ROUTES.reasons,
  },
  {
    key: ROUTES.payments,
    icon: <StrikethroughOutlined />,
    label: 'Stripe payments info',
    to: ROUTES.payments,
  },
]

const logoutItem = [
  {
    key: 'Logout',
    icon: <LogoutOutlined />,
    label: 'Logout',
  },
]

interface IProps {
  collapsed: boolean
  onCollapse: (collapsed: boolean) => void
}

export const Sidebar: FC<IProps> = ({ collapsed, onCollapse }) => {
  const location = useLocation()
  const [logoutMutation] = useLogoutMutation()

  const onLogout = useCallback(() => {
    logoutMutation()
  }, [logoutMutation])

  const selectedKeys = useMemo(() => {
    const activeItem = menuList.find((item) => location.pathname.includes(item.to))

    return activeItem?.key ?? menuList[0].key
  }, [location.pathname])

  return (
    <Layout.Sider
      className={styles.root}
      collapsible={true}
      collapsed={collapsed}
      onCollapse={onCollapse}
    >
      <div className={styles.children}>
        <div className={styles.menu}>
          <Menu theme='dark' mode='inline' selectedKeys={[selectedKeys]}>
            {menuList.map((item) => (
              <Menu.Item key={item.key} icon={item.icon}>
                <Link state={{ prevPath: location }} to={item.to}>
                  {item.label}
                </Link>
              </Menu.Item>
            ))}
          </Menu>
        </div>
        <div className={styles.footer}>
          <Menu theme='dark' mode='inline' items={logoutItem} onClick={onLogout} />
        </div>
      </div>
    </Layout.Sider>
  )
}
