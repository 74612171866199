import { FC, ReactNode } from 'react'
import cn from 'classnames'

// Styles
import styles from './Layout.module.scss'

interface IProps {
  className?: string
  isCenteredVertical?: boolean
  children: ReactNode
}

export const Layout: FC<IProps> = ({ className, isCenteredVertical = true, children }) => {
  return (
    <div className={cn(styles.root, { [styles.vertical]: isCenteredVertical }, className)}>
      {children}
    </div>
  )
}
