import { FC } from 'react'

// UIKit
import { Typography } from 'antd'

const { Text } = Typography

interface IProps {
  text?: string
}

export const HelperText: FC<IProps> = ({ text }) => {
  return (
    <Text type='danger' ellipsis style={{ marginTop: '0.2rem' }}>
      {text}
    </Text>
  )
}
