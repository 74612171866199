import { api } from '@/app/services/api'
import { transformCitiesResponse, transformCountriesResponse } from './Countries.utils'

const ROOT_ENDPOINT = 'countries'

export const countriesApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getCountries: builder.query({
      query: () => ({
        url: ROOT_ENDPOINT,
      }),
      providesTags: ['Countries'],
      transformResponse: transformCountriesResponse,
    }),

    getCitiesByCountry: builder.query({
      query: (countryName) => ({
        url: `${ROOT_ENDPOINT}/${countryName}`,
      }),
      transformResponse: transformCitiesResponse,
    }),
  }),
})

export const { useGetCountriesQuery, useGetCitiesByCountryQuery } = countriesApi
