import { FC, memo } from 'react'
import { Outlet } from 'react-router-dom'
import cn from 'classnames'

// Styles
import styles from './Content.module.scss'

// UIKit
import { Layout as AntLayout } from 'antd'

interface IProps {
  collapsed: boolean
}

let Content: FC<IProps> = ({ collapsed }) => {
  return (
    <AntLayout>
      <AntLayout.Content className={cn(styles.root, { [styles.collapsed]: collapsed })}>
        <Outlet />
      </AntLayout.Content>
    </AntLayout>
  )
}

Content = memo(Content)

export { Content }
