import { FC, useCallback } from 'react'
import { Navigate, Outlet, useLocation } from 'react-router-dom'

// Constants
import { ROUTES } from '@/features/routingBook'

// Hooks
import useRouterChange from '@/common/hooks/useRouterChange'

// Store
import { useAppDispatch, useAppSelector } from '@/app/store'
import { isAuthenticatedSelector } from '@/features/Auth/Auth.selectors'
import { logout } from '@/features/Auth/Auth.slice'

export const RequireAuth: FC = () => {
  const location = useLocation()
  const dispatch = useAppDispatch()

  const isAuthenticated = useAppSelector(isAuthenticatedSelector)

  const checkIsSignIn = useCallback(() => {
    !isAuthenticated && dispatch(logout())
  }, [dispatch, isAuthenticated])

  useRouterChange(checkIsSignIn)

  return isAuthenticated ? (
    <Outlet />
  ) : (
    <Navigate to={ROUTES.login} state={{ from: location }} replace />
  )
}
