/* eslint-disable @typescript-eslint/no-explicit-any */
import { combineReducers } from '@reduxjs/toolkit'

import { api } from '@/app/services/api'
import authReducer from '@/features/Auth/Auth.slice'
import userReducer from '@/features/User/User.slice'
import countriesReducer from '@/features/Dictionaries/Countries/Countries.slice'

const combinedReducer = combineReducers({
  [api.reducerPath]: api.reducer,
  auth: authReducer,
  user: userReducer,
  countries: countriesReducer,
})

type RootState = ReturnType<typeof combinedReducer>

export const rootReducer = (state: any, action: any) => {
  if (action.type === 'auth/logout') {
    state = {} as RootState
  }
  return combinedReducer(state, action)
}
