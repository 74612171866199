import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux'
import { configureStore, PreloadedState } from '@reduxjs/toolkit'
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist'

import { rootReducer } from './rootReducer'
import { persistConfig } from './persistConfig'
import { isProduction } from '@/common/utils/environments'
import { rtkQueryErrorLogger } from '@/common/middleware/rtkQueryErrorLogger'

import { api } from '@/app/services/api'

const persistedReducer = persistReducer(persistConfig, rootReducer)

const middlewareConfig = {
  serializableCheck: {
    ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
  },
}

export const setupStore = (preloadedState?: PreloadedState<TRootState>) => {
  return configureStore({
    reducer: persistedReducer,
    middleware: (gDM) => gDM(middlewareConfig).concat([api.middleware, rtkQueryErrorLogger]),
    devTools: !isProduction(),
    preloadedState,
  })
}

export const store = setupStore()

export const persistor = persistStore(store)

export type TRootState = ReturnType<typeof rootReducer>
export type TAppStore = ReturnType<typeof setupStore>
export type TAppDispatch = TAppStore['dispatch']

// Hooks with predefined types
export const useAppDispatch = () => useDispatch<TAppDispatch>()
export const useAppSelector: TypedUseSelectorHook<TRootState> = useSelector
