import { isRejectedWithValue, Middleware, MiddlewareAPI } from '@reduxjs/toolkit'
import { logout } from '@/features/Auth/Auth.slice'

import { notification } from 'antd'
import { serializeApiError } from '../utils/api'

export const rtkQueryErrorLogger: Middleware =
  ({ dispatch }: MiddlewareAPI) =>
  (next) =>
  (action) => {
    if (isRejectedWithValue(action)) {
      const statusCode = action.payload?.status

      console.log('We got a rejected action!', { action })

      notification.error(serializeApiError(action.payload))

      if (statusCode === 401) {
        return dispatch(logout())
      }
    }

    return next(action)
  }
