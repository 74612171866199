import { api } from '@/app/services/api'
import { IStripeKeys, IUser } from '@/common/models/user'

const ROOT_ENDPOINT = 'partner'

export const userApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getUser: builder.query<IUser, void>({
      query: () => ({
        url: ROOT_ENDPOINT,
      }),
      providesTags: ['User'],
    }),

    updateUser: builder.mutation<IUser, Partial<IUser>>({
      query: ({ _id, ...body }) => ({
        url: `${ROOT_ENDPOINT}/${_id}`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: ['User'],
    }),

    getStripeSecretKey: builder.query<IStripeKeys, void>({
      query: () => ({
        url: `${ROOT_ENDPOINT}/stripeSecretKey`,
      }),
      providesTags: ['User'],
    }),

    setStripeSecretKey: builder.mutation<IStripeKeys, IStripeKeys>({
      query: (body) => ({
        url: `${ROOT_ENDPOINT}/stripeSecretKey`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: ['User'],
    }),
  }),
})

export const {
  useGetUserQuery,
  useLazyGetUserQuery,
  useUpdateUserMutation,
  useGetStripeSecretKeyQuery,
  useSetStripeSecretKeyMutation,
} = userApi

export const {
  endpoints: { getUser, updateUser },
} = userApi
