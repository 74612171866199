export const ROUTES = Object.freeze({
  root: '/',

  login: '/login',
  passwordRecovery: '/recovery',
  passwordReset: '/api/v1/forgotPassword/reset',

  forbidden: '/forbidden',
  notfound: '/notfound',

  location: '/locations/:id',
  locationMenus: '/locations/:id/menus',
  locationCategories: '/locations/:id/categories',
  locationDishes: '/locations/:id/dishes',
  locationTables: '/locations/:id/tables',
  locations: '/locations',
  newLocation: '/locations/create',

  promotions: '/promotions',

  reasons: '/reasons',

  payments: '/payments',
})
