import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

const useRouterChange = (callback: () => void) => {
  const location = useLocation()

  useEffect(callback, [location, callback])
}

export default useRouterChange
