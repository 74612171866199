import { createApi, fetchBaseQuery, retry } from '@reduxjs/toolkit/query/react'

// Constants
import { BASE_URL } from '@/common/constants/api'

// Store
import { tokenSelector } from '@/features/Auth/Auth.selectors'
import { TRootState } from '../store'

const baseQuery = fetchBaseQuery({
  baseUrl: BASE_URL,
  prepareHeaders: (headers, { getState }) => {
    const token = tokenSelector(getState() as TRootState)

    if (token) {
      headers.set('Authorization', `Bearer ${token}`)
    }

    return headers
  },
})

const baseQueryWithRetry = retry(baseQuery, { maxRetries: 2 })

export const api = createApi({
  reducerPath: 'Api',
  baseQuery: baseQueryWithRetry,
  keepUnusedDataFor: 0,
  tagTypes: [
    'User',
    'Locations',
    'Location',
    'Countries',
    'Cities',
    'Dishes',
    'MenuCategories',
    'Menus',
    'Tables',
    'Promotions',
    'RejectReasons',
  ],
  endpoints: () => ({}),
})
