import { ROUTES } from '@/features/routingBook'
import { Button, Result } from 'antd'
import { useNavigate } from 'react-router-dom'

// Components
import { Layout } from '@/common/components/Layout/Layout/Layout'

export const Forbidden = () => {
  const navigate = useNavigate()

  const onClick = () => navigate(ROUTES.root)

  return (
    <Layout>
      <Result
        status='403'
        title='403'
        subTitle='Sorry, you are not authorized to access this page.'
        extra={
          <Button type='primary' onClick={onClick}>
            Back Home
          </Button>
        }
      />
    </Layout>
  )
}
