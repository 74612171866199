import { TCountries, TList } from './Countries.types'

export const transformCountriesResponse = (countries: TCountries): TList => {
  return countries.map((country) => ({ label: country.country, value: country.country }))
}

export const transformCitiesResponse = (countries: TCountries): TList => {
  const transformCity = (city: string) => ({ label: city, value: city })

  return countries[0].cities.map(transformCity)
}
