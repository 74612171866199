import { FC, useEffect, useState } from 'react'

// Styles
import styles from './index.module.scss'

// UIKit
import { Layout as AntLayout } from 'antd'

// Components
import { Sidebar } from './Sidebar/Sidebar'
import { Content } from './Content/Content'
import { useLazyGetUserQuery } from '@/features/User/User.service'

import { isAuthenticatedSelector } from '@/features/Auth/Auth.selectors'
import { useAppDispatch, useAppSelector } from '@/app/store'
import { isUserLoadedSelector } from '@/features/User/User.selectors'

export const MainLayout: FC = () => {
  const dispatch = useAppDispatch()
  const isAuthenticated = useAppSelector(isAuthenticatedSelector)
  const isUserLoaded = useAppSelector(isUserLoadedSelector)

  const [collapsed, setCollapsed] = useState(false)

  const [getUser] = useLazyGetUserQuery()

  const onCollapse = (value: boolean) => setCollapsed(value)

  useEffect(() => {
    isAuthenticated && !isUserLoaded && getUser()
  }, [isAuthenticated, getUser, dispatch, isUserLoaded])

  return (
    <AntLayout className={styles.root}>
      <Sidebar collapsed={collapsed} onCollapse={onCollapse} />
      <Content collapsed={collapsed} />
    </AntLayout>
  )
}
